/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var EDS = {
    // All pages
    'common': {
      init: function() {
        var that = this;

        this.initBackgroundImages();
        this.initSideNav();
        this.initScrollTo();
        this.initDownloads();
        this.initTermCarousels();
        this.initNewsCarousel();
        this.manageStickyNav();
        this.manageMarkerPositions();
        this.manageResponsiveTableInfo();
        EDS.manageEheight();

        $(window).resize(function() {
          that.manageStickyNav();
          that.manageMarkerPositions();
          that.manageResponsiveTableInfo();
          EDS.manageEheight();
        });

        $(window).on('scroll wheel touchstart touchend swipe', function() {
          that.manageStickyNav();

          setTimeout(function() {
            that.manageStickyNav();
          }, 100);
        });
      },
      initBackgroundImages: function() {
        var isRetina = EDS.isRetina();

        $('[data-bg]').each(function(){
          var src = $(this).data('bg');

          if (src) {
            var src_retina = $(this).data('bg-retina');

            if (isRetina && src_retina) {
              src = src_retina;
            }

            $(this).css('background-image', 'url(' + src + ')');
          }
        });
      },
      initSideNav: function () {
        bouncefix.add('side-nav-container');
        bouncefix.add('side-nav-backdrop');

        $(".btn-toggle-side").click(function() {
          $('body').toggleClass("side-nav-active");
        });

        $(".side-nav-backdrop, .side-nav-container").click(function(e){
          if ($(e.target).closest(".side-nav").length === 0) {
            $('body').removeClass("side-nav-active");
          }
        });
      },
      initDownloads: function() {
        $(document).on("click", "a.file-download", function(e) {
          e.preventDefault();
          $.fileDownload($(this).attr('href'));
          return false;
        });
      },
      initScrollTo: function() {
        $(".btn-scroll").click(function(e) {
          e.preventDefault();

          var offset;
          var target = $(this).data('target');

          if($(target).length){
            if(target === 'body') {
              offset = 0;
            } else {
              offset = $(target).offset().top;
            }

            if(!Modernizr.mq("(min-width: 992px)")) {
              offset -= $('.sticky-nav').outerHeight();
            }

            $('html, body').animate({
              scrollTop: offset
            }, 1000);
          }
        });
      },
      initTermCarousels: function() {
        $('.pf-tc-carousel').each(function() {
          var carousel = $(this).slick({
            arrows: false,
            dots: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            touchThreshold: 10,
            adaptiveHeight: true,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true
                }
              }
            ]
          });

          carousel.on('setPosition', function() {
            EDS.manageEheight();
          });
        });
      },
      initNewsCarousel: function() {
        var $newsCarousel = $('.news-carousel');

        var autoPlay = $newsCarousel.data('autoplay') === 'enabled' ? true : false;
        var speed = $newsCarousel.data('speed') ? $(this).data('speed') : 4000;

        $newsCarousel.slick({
          autoplay: autoPlay,
          autoplaySpeed: speed,
          arrows: false,
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1
        });
      },
      manageStickyNav: function() {
        var scrollTop = $(window).scrollTop();
        var $stickyNav = $('.sticky-nav');
        var bannerHeight = $('.banner').outerHeight() - $stickyNav.outerHeight();

        if (scrollTop >= bannerHeight) {
          $('body').addClass('sticky-nav-active');
        } else {
          $('body').removeClass('sticky-nav-active');
        }
      },
      manageResponsiveTableInfo: function() {
        $('.table-responsive').each(function(){
          var el = $(this).get(0);
          var info = $(this).parent().find('.table-info');
          if(el.clientWidth < el.scrollWidth) {
            info.show();
          } else {
            info.hide();
          }
        });
      },
      manageMarkerPositions: function() {
        var that = this;

        $(".locations-map-wrap").each(function(){
          var $wrap = $(this);

          if ($wrap.hasClass("map-loaded")) {
            that.setMarkerPositions($wrap);
          } else {
            $wrap.imagesLoaded(function() {
              $wrap.addClass("map-loaded");
              that.setMarkerPositions($wrap);
            });
          }
        });
      },
      setMarkerPositions: function(wrap) {
        var $markers = $(".marker", wrap);

        if (wrap.width() < wrap.data("base-width")) {
          var wP = (wrap.width() / wrap.data("base-width"));
          var hP = (wrap.height() / wrap.data("base-height"));

          $markers.addClass('small');
          $markers.each(function() {
            $(this).css({
              top: hP * parseInt($(this).data("top")),
              left: wP * parseInt($(this).data("left"))
            });
          });
        } else {
          $markers.removeClass('small');
          $markers.each(function() {
            $(this).css({
              top: $(this).data("top"),
              left: $(this).data("left")
            });
          });
        }
      }
    },
    // Front Page
    'home': {
      init: function() {
        this.initPageHeaderCarousel();
      },
      initPageHeaderCarousel: function() {
        var $carousel = $('.ph-background-carousel');
        var interval = $carousel.data('interval');

          $carousel.slick({
          arrows: false,
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: interval,
          fade: true,
          cssEase: 'linear'
        });
      }
    },
    // Distributor Archive
    'post_type_archive_eds_distributor': {
      init: function() {
        this.initLocationEngine();
      },
      initLocationEngine: function() {
        var $dContent = $('.distributors-content');
        var $backButton = $(".back-to-countries", $dContent);
        var $countryList = $(".loc-countries", $dContent);
        var $distributors = $('.loc-distributor', $dContent);
        var $map = $(".locations-map-wrap", $dContent);
        var $markers = $(".marker", $map);

        $markers.on("click", function(e) {
          e.preventDefault();

          if(! $(this).hasClass("active")) {

            $backButton.removeClass('hidden');
            $countryList.addClass('hidden');
            $markers.removeClass('active');
            $('.loc-placeholder', $dContent).hide();
            $distributors.addClass('hidden');

            $(this).addClass('active');
            $($(this).data('target')).removeClass('hidden');
          }
        });

        $(".loc-country a", $dContent).on("click", function(e) {
          e.preventDefault();

          id = $(this).data('country-id');

          $countryList.addClass('hidden');
          $markers.removeClass('active');
          $map.find('.country-' + id).addClass('active');
          $('.loc-distributors .country-' + id, $dContent).removeClass('hidden');
          $backButton.removeClass('hidden');
        });

        $backButton.on("click", function(e) {
          e.preventDefault();

          $backButton.addClass('hidden');
          $countryList.removeClass('hidden');
          $distributors.addClass('hidden');
          $markers.removeClass('active');
        });
      }
    },
    // Single Product
    'single_eds_product': {
      init: function() {
        var that = this;

        this.initShare();
        this.initGallery();
        this.initKeyInfoCollapse();
        this.manageKeyInfoCollapse();

        $(window).resize(function() {
          that.manageKeyInfoCollapse();
        });
      },
      initKeyInfoCollapse: function() {
        var $container =  $('.product-key-info');
        var $btn = $('.btn-read', $container);
        var $table = $('.table-responsive', $container);

        $btn.click(function(e) {
          e.preventDefault();
          $btn.toggleClass('hidden');
          $table.toggleClass('active');
        });
      },
      manageKeyInfoCollapse: function() {
        var $container =  $('.product-key-info');
        var $table = $('.table-responsive', $container);
        var $btn = $('.btn-read', $container);

        if ($table[0].scrollHeight < 250) {
          $table.removeClass('active');
          $btn.removeClass('active');
        } else {
          if($('.btn-read.more').hasClass('hidden')) {
            $table.addClass('active');
          }
          $btn.addClass('active');
        }
      },
      initShare: function() {
        $('.entry-share-btns .entry-share-btn a').on('click', function(e) {
          e.preventDefault();
          var link = $(this).attr('href');
          var width = 840;
          var height = 464;
          var popupName = 'popup_' + width + 'x' + height;
          var left = (screen.width-width) / 2;
          var top = 100;
          var params = 'width=' + width + ',height=' + height + ',location=no,menubar=no,scrollbars=yes,status=no,toolbar=no,left=' + left + ',top=' + top;
          window[popupName] = window.open(link, popupName, params);
          if (window.focus) {
            window[popupName].focus();
          }
          return true;
        });
      },
      initGallery: function() {
        $('.product-gallery').slick({
          arrows: false,
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          asNavFor: '.product-thumbnails',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                dots: true
              }
            }
          ]
        });

        $('.product-thumbnails').slick({
          dots: false,
          arrows: false,
          infinite: true,
          slidesToShow: 1,
          centerMode: true,
          variableWidth: true,
          focusOnSelect: true,
          asNavFor: '.product-gallery'
        });
      }
    },
    // Contact Template
    'page_template_template_contact_php': {
      init: function () {
        var that = this;

        var maps = this.initMaps();
        this.manageMaps(maps);

        $(window).resize(function() {
          that.manageMaps(maps);
        });
      },
      initMaps: function () {
        var that = this;
        var maps = [];

        $('.page-header.map .eds-map').each(function(index){
          maps[index] = that.render_map( $(this) );
        });

        return maps;
      },
      manageMaps: function(maps) {
        $.each( maps, function(index, map){
          var currCenter = map.getCenter();
          google.maps.event.trigger(map, 'resize');
          map.setCenter(currCenter);
        });
      },
      render_map: function( $el ) {
        var that = this;

        // var
        var $markers = $el.find('.marker');

        // vars
        var args;

        args = {
          zoom		  : 14,
          center		: new google.maps.LatLng(0, 0),
          mapTypeId	: google.maps.MapTypeId.ROADMAP,
          scaleControl: false,
          draggable: false,
          scrollwheel: false
        };

        // create map
        var map = new google.maps.Map( $el[0], args);

        // add a markers reference
        map.markers = [];

        // add markers
        $markers.each(function(){
          that.add_marker( $(this), map );
        });

        // center map
        that.center_map( map );

        return map;
      },
      add_marker: function( $marker, map ) {
        // var
        var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

        // create marker
        var marker = new google.maps.Marker({
          position	: latlng,
          map			: map
        });

        // add to array
        map.markers.push( marker );

        // if marker contains HTML, add it to an infoWindow
        if( $marker.html() ) {
          // create info window
          var infowindow = new google.maps.InfoWindow({
            content		: $marker.html()
          });

          // show info window when marker is clicked
          google.maps.event.addListener(marker, 'click', function() {
            infowindow.open( map, marker );
          });
        }
      },
      center_map: function( map ) {
        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each( map.markers, function( i, marker ){
          var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
          bounds.extend( latlng );
        });

        // only 1 marker?
        if( map.markers.length === 1 ) {
          // set center of map
          map.setCenter( bounds.getCenter() );
        } else {
          // fit to bounds
          map.fitBounds( bounds );
        }
      }
    },
    isRetina: function() {
      var mediaQuery = '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)';

      if (window.devicePixelRatio > 1) {
        return true;
      }

      if (window.matchMedia && window.matchMedia(mediaQuery).matches) {
        return true;
      }

      return false;
    },
    manageEheight: function() {
      $(".eh-container").each(function() {
        var minWidth = parseInt($(this).data("min")) || 0;
        var children = $(this).find(".eh-child");
        var maxHeight = 0;

        if(Modernizr.mq("(min-width: " + minWidth + "px)")) {
          $(this).imagesLoaded(function() {
            children.each(function() {
              $(this).css({
                "height": "auto"
              });

              if($(this).outerHeight() > maxHeight) {
                maxHeight = $(this).outerHeight();
              }
            });

            children.css({
              "height": maxHeight
            });
          });
        } else {
          children.css({
            "height": "auto"
          });
        }
      });
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = EDS;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
